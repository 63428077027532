<template>
<div class="LoginFormCode">
  
  <a-form
    ref="form"
    :model="formState"
    :rules="formRules"
    layout="vertical"
    validateTrigger="onSubmit"
    :hideRequiredMark="true"
    @finish="onSubmit"
  >
    
    <a-form-item
      class="ant-form-item-primary"
      name="form_code"
      :label="formSetup.code.label"
    >
      <a-input-number
        class="LoginFormCode__input"
        :placeholder="formSetup.code.placeholder"
        v-model:value="form_code"
        :controls="false"
        :disabled="loading"
        @change="onChangeCode"
        :stringMode="true"
        size="large"
      />
    </a-form-item>
    
    <a-button
      class="ant-btn-huge LoginFormCode__submit"
      type="green"
      size="large"
      html-type="submit"
      :loading="loading"
    >
      Next
    </a-button>
    
  </a-form>
  
</div>
</template>

<script>
import { mapFields } from '@/helpers/form.helper'
import { mapMutations, mapGetters, mapActions } from "vuex"
import { toast } from '@/helpers/error.helper'
import { Patient } from "@/models/api_v1"
import IMask from 'imask'

let serverErr, formSetup = {
  code: {
    label: 'Code',
    placeholder: '1234',
    rules: [
      { required: true, message: 'Please enter code' },
      { pattern: /^\d{4}$/, message: 'Invalid code' },
      { validator: async () => Promise[serverErr?.code?.length ? 'reject' : 'resolve'](serverErr?.code?.[0]) },
    ],
  },
}

export default {
  name: "LoginFormCode",
  data() {
    return {
      loading: false,
      formSetup,
    }
  },
  computed: {
    ...mapGetters({
      init: 'PatientInitialStore/getInit',
      getPayload: 'PatientPatientStore/getPayload',
    }),
    ...mapFields({
      formSetup,
      props: ['fields', 'formState', 'formRules'],
      getter: (vm, field) => vm.getPayload('Auth')[field],
      setter: (vm, field, value) => vm.setPayloadField({ name: 'Auth', field, value}),
    }),
    testCurrent() {
      return this.init?.test_items?.find(item => this.getPayload('Auth').test_item_id === item.id)
    },
    codeMask() {
      return IMask.createMask({
        mask: '0000', // 1234
      })
    },
  },
  methods: {
    ...mapActions({
      patientCode: 'PatientPatientStore/code',
    }),
    ...mapMutations({
      setPayloadField: 'PatientPatientStore/setPayloadField',
      setPatient: 'PatientPatientStore/setPatient',
      setAgreement: 'PatientPatientStore/setAgreement',
      setTest: 'PatientPatientStore/setTest',
    }),
    onChange() {
      this.$refs.form.clearValidate()
      serverErr = null
    },
    onChangeCode() {
      this.onChange()
      this.codeMask.resolve(this.form_code)
      this.form_code = this.codeMask.value
    },
    async onSubmit() {
      let payload = this.getPayload('Auth').toApi()
      this.loading = true
      await this.patientCode({ payload })
        .then((response) => {
          if (response instanceof Error || response?.data?.data?.success === false) throw response
          toast ({ type: 'success', msg: 'Success' })
          let { patient, agreement, ...data } = response.data.data
          let self = Patient.fromApi({ ...patient, ...data })
          localStorage.setItem('access_token', self.access_token)
          this.setPatient(self)
          this.setAgreement(agreement)
          this.setTest({ ...this.testCurrent, barcode: payload.barcode })
          this.$router.push({ name: 'patient.auth.user' })
        })
        .catch((err) => {
          serverErr = err.response?.data?.errors
          toast ({ msg: err?.data?.data?.message })
          this.$refs.form.validateFields()
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
  mounted() {
    if (!this.getPayload('Auth').phone) {
      this.$router.push({ name: 'patient.auth' })
    }
  }
}
</script>

<style lang="scss" scoped>
.LoginFormCode {
  padding: 24px 0;
  
  &__input {
    width: 100%;
  }
  
  &__submit {
    margin-top: 10px;
  }
  
}
</style>