<template>
<div class="LoginFormAuth">
  
  <img
    class="LoginFormAuth__img"
    src="@/assets/img/Glucose-Grey-scaled-1.png"
  >
  
  <a-form
    ref="form"
    :model="formState"
    :rules="formRules"
    layout="vertical"
    validateTrigger="onSubmit"
    :hideRequiredMark="true"
    @finish="onSubmit"
  >
    
    <a-form-item
      class="ant-form-item-primary"
      name="form_phone"
      :label="formSetup.phone.label"
    >
      <vue-tel-input
        ref="form_phone"
        v-model="form_phone"
        :dropdownOptions="{ showFlags: true, showDialCodeInList: true, width: '100%' }"
        :inputOptions="{ showDialCode: true }"
        :defaultCountry="'GB'"
        :preferredCountries="['GB', 'US', 'CA', 'FR', 'DE']"
        :disabled="loading"
        @on-input="onChangePhone"
        @validate="onValidatePhone"
      />
    </a-form-item>
    
    <a-form-item
      class="ant-form-item-primary"
      name="form_barcode"
      :label="formSetup.barcode.label"
    >
      <a-input
        v-model:value="form_barcode"
        :placeholder="formSetup.barcode.placeholder"
        :disabled="loading"
        @change="onChangeBarcode"
        size="large"
      />
    </a-form-item>
    
    <a-form-item
      class="ant-form-item-primary"
      name="form_test_item_id"
      :label="formSetup.test_item_id.label"
    >
      <a-select
        v-model:value="form_test_item_id"
        :options="init.test_items"
        :placeholder="formSetup.test_item_id.placeholder"
        :fieldNames="{ label: 'title', value: 'id' }"
        :disabled="loading"
        @change="onChange"
        size="large"
      >
        <template #suffixIcon>
          <CaretDownOutlined
            class="ant-select-suffix ant-select-caret"
          />
        </template>
      </a-select>
    </a-form-item>
    
    <a-button
      class="ant-btn-huge LoginFormAuth__submit"
      type="green"
      size="large"
      html-type="submit"
      :loading="loading"
    >
      Next
    </a-button>
    
  </a-form>
    
</div>
</template>

<script>
import { CaretDownOutlined } from '@ant-design/icons-vue'
import { mapFields } from '@/helpers/form.helper'
import { mapMutations, mapGetters, mapActions } from "vuex"
import { toast } from '@/helpers/error.helper'
import IMask from 'imask'

let phoneValid, serverErr, formSetup = {
  phone: {
    label: 'Phone',
    rules: [
      { validator: async () => Promise[!phoneValid ? 'reject' : 'resolve']('Invalid phone number') },
      { validator: async () => Promise[serverErr?.phone?.length ? 'reject' : 'resolve'](serverErr?.phone?.[0]) },
    ],
  },
  barcode: {
    label: 'Barcode',
    placeholder: 'FGD123456',
    rules: [
      { required: true, message: 'Please enter barcode' },
      { pattern: /^[a-zA-Z]{3}\d{6}$/, message: 'Invalid barcode' },
      { validator: async () => Promise[serverErr?.barcode?.length ? 'reject' : 'resolve'](serverErr?.barcode?.[0]) },
    ],
  },
  test_item_id: {
    label: 'Test type',
    placeholder: 'Select',
    rules: [
      { required: true, message: 'Please enter test type' },
      { validator: async () => Promise[serverErr?.test_item_id?.length ? 'reject' : 'resolve'](serverErr?.test_item_id?.[0]) },
    ],
  },
}

export default {
  name: "LoginFormAuth",
  components: {
    CaretDownOutlined,
  },
  data() {
    return {
      patientTokenValue: localStorage.getItem('access_token') || '',
      loading: false,
      formSetup,
    }
  },
  computed: {
    ...mapGetters({
      init: 'PatientInitialStore/getInit',
      getPayload: 'PatientPatientStore/getPayload',
      getPatient: 'PatientPatientStore/getPatient',
    }),
    ...mapFields({
      formSetup,
      props: ['fields', 'formState', 'formRules'],
      getter: (vm, field) => vm.getPayload('Auth')[field],
      setter: (vm, field, value) => vm.setPayloadField({ name: 'Auth', field, value}),
    }),
    barcodeMask() {
      return IMask.createMask({
        mask: '###000000', // FGD123456
        definitions: {
          '#': /[a-zA-Z]/
        }
      })
    },
    patientToken: {
      get: function() {
        return this.patientTokenValue || this.getPatient?.access_token
      },
      set: function(value) {
        this.patientTokenValue = value
        if (value) {
          localStorage.setItem('access_token', value)
        } else {
          localStorage.removeItem('access_token')
        }
      }
    },
  },
  methods: {
    ...mapActions({
      patientLogin: 'PatientPatientStore/login',
    }),
    ...mapMutations({
      setPayloadField: 'PatientPatientStore/setPayloadField',
    }),
    onChange() {
      this.$refs.form.clearValidate()
      serverErr = null
    },
    onChangeBarcode() {
      this.onChange()
      this.barcodeMask.resolve(this.form_barcode)
      this.form_barcode = this.barcodeMask.value.toUpperCase()
    },
    onChangePhone(number, phoneObject) {
      let clear = number
      clear = number.replace(/(-)\1+/g, '$1')

      if ((number.match(/\+/g) || []).length > 1) {
          this.$nextTick(() => {
              let i = 0
              clear = number.replace(/\+/g, (k) => (!i++ ? k : ``))
          });
      }
      if (number.trim().length === 0) {
          clear = '+'
      }
      if (phoneObject.valid && phoneObject.countryCode === "GB") {
          clear = `+${phoneObject.countryCallingCode} ${phoneObject.nationalNumber.replace(/^(\d{2})(\d{4})(\d{4})$/, '$1 $2 $3')}`
      }

      this.$nextTick(() => {
          this.form_phone = clear
      });
    },
    onValidatePhone(phoneObject) {
      return phoneValid = phoneObject.valid
    },
    async onSubmit() {
      let payload = this.getPayload('Auth').toApi()
      this.loading = true
      await this.patientLogin({ payload })
        .then((response) => {
          if (response instanceof Error || response?.data?.data?.success === false) throw response
          toast ({ type: 'success', msg: 'Success' })
          this.$router.push({ name: 'patient.auth.code' })
        })
        .catch((err) => {
          if(err?.response?.data?.message === "Test already exist and completed."){
            this.$emit('testCompleted')
            return
          }
          serverErr = err.response?.data?.errors
          toast ({ msg: err?.data?.data?.message })
          this.$refs.form.validateFields()
        })
        .finally(() => {
          this.loading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.LoginFormAuth {
  padding: 24px 0;
  
  &__img {
    width: 100%;
    margin-bottom: 24px;
  }
  
  &__submit {
    margin-top: 10px;
  }
  
}
</style>